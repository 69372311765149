































































import Vue from 'vue'
import Location, { isEmpty } from '@/models/Location';
// components
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialMaskedInput from '@/components/MaterialMaskedInput.vue';
import MaterialSelect from '@/components/MaterialSelect.vue';
// validation
import { ValidationProvider, extend } from 'vee-validate';
import { regex } from 'vee-validate/dist/rules';

extend('regex', {
    ...regex,
    message: '{_field_} is not in the expected format'
});

interface State { id: string, name: string }
interface Country { id: string, name: string }

interface Data {
    states: State[];
    countries: Country[];
    isEdit: boolean;
}

export default Vue.extend({
    name: 'AddLocation',
    components: {
        MaterialInput,
        MaterialMaskedInput,
        MaterialSelect,
        ValidationProvider
    },
    props: {
        location: { type: Object as () => Location, required: true }
    },
    data(): Data {
        return {
            states: [],
            countries: [],
            isEdit: false
        }
    },
    computed: {
        addDisabled(): boolean {
            return isEmpty(this.location);
        }
    },
    methods: {
        save() {
            this.$emit(this.isEdit ? 'onUpdate' : 'onAdd', this.location);
        },
        cancel() {
            this.$emit("onCancel")
        },
        setPrimary() {
            this.$emit('onPrimary', this.location.rank)
        }
    },
    mounted() {
        this.isEdit = !isEmpty(this.location);
        
        this.countries = [
            {id: 'USA', name: 'USA' },
            {id: 'Canada', name: 'Canada' }
        ]

        // states array
        this.states = [
            { id: 'AK', name: 'AK' },
            { id: 'AL', name: 'AL' },
            { id: 'AR', name: 'AR' },
            { id: 'AZ', name: 'AZ' },
            { id: 'CA', name: 'CA' },
            { id: 'CO', name: 'CO' },
            { id: 'CT', name: 'CT' },
            { id: 'DC', name: 'DC' },
            { id: 'DE', name: 'DE' },
            { id: 'FL', name: 'FL' },
            { id: 'GA', name: 'GA' },
            { id: 'HI', name: 'HI' },
            { id: 'IA', name: 'IA' },
            { id: 'ID', name: 'ID' },
            { id: 'IL', name: 'IL' },
            { id: 'IN', name: 'IN' },
            { id: 'KS', name: 'KS' },
            { id: 'KY', name: 'KY' },
            { id: 'LA', name: 'LA' },
            { id: 'MA', name: 'MA' },
            { id: 'MD', name: 'MD' },
            { id: 'ME', name: 'ME' },
            { id: 'MI', name: 'MI' },
            { id: 'MN', name: 'MN' },
            { id: 'MO', name: 'MO' },
            { id: 'MS', name: 'MS' },
            { id: 'MT', name: 'MT' },
            { id: 'NC', name: 'NC' },
            { id: 'ND', name: 'ND' },
            { id: 'NE', name: 'NE' },
            { id: 'NH', name: 'NH' },
            { id: 'NJ', name: 'NJ' },
            { id: 'NM', name: 'NM' },
            { id: 'NV', name: 'NV' },
            { id: 'NY', name: 'NY' },
            { id: 'OH', name: 'OH' },
            { id: 'OK', name: 'OK' },
            { id: 'OR', name: 'OR' },
            { id: 'PA', name: 'PA' },
            { id: 'RI', name: 'RI' },
            { id: 'SC', name: 'SC' },
            { id: 'SD', name: 'SD' },
            { id: 'TN', name: 'TN' },
            { id: 'TX', name: 'TX' },
            { id: 'UT', name: 'UT' },
            { id: 'VA', name: 'VA' },
            { id: 'VT', name: 'VT' },
            { id: 'WA', name: 'WA' },
            { id: 'WI', name: 'WI' },
            { id: 'WV', name: 'WV' },
            { id: 'WY', name: 'WY' },
            { id: 'AB', name: 'AB' },
            { id: 'BC', name: 'BC' },
            { id: 'MB', name: 'MB' },
            { id: 'NB', name: 'NB' },
            { id: 'NL', name: 'NL' },
            { id: 'NT', name: 'NT' },
            { id: 'NS', name: 'NS' },
            { id: 'NU', name: 'NU' },
            { id: 'ON', name: 'ON' },
            { id: 'PE', name: 'PE' },
            { id: 'QC', name: 'QC' },
            { id: 'SK', name: 'SK' },
            { id: 'YT', name: 'YT' }
        ]
    }
})
