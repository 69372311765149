





























































































































import Vue from 'vue'
import api from '@/api';
import { mask } from 'vue-the-mask';
import PaymentMethod, { BankAccount, CreditCard, PaymentType } from '@/models/PaymentMethod';
import Company from '@/models/Company';
import { ProfilePermissions } from '@/models/Permissions';
import { EffectivePrivileges } from '@/models/Privileges';
//components
import PaymentMethodListItem from '@/components/profile/PaymentMethodListItem.vue';
import SkVueCard from '@/components/profile/SkVueCard.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialSelect from '@/components/MaterialSelect.vue';
//validation
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, regex } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: '{_field_} is required'
});

extend('regex', {
    ...regex,
    message: '{_field_} is not in the expected format'
});

export enum AddState {
    None = 0,
    AddingCC = 2,
    AddingACH = 4
}

interface Item {
    id: number,
    name: string
}

interface PayModel {
    id: number,
    name: string,
    primary: boolean
}

interface Data {
    state: AddState;
    methods: PaymentMethod[];
    newCC: CreditCard | null;
    newACH: BankAccount | null;
    accountTypes: Item[];
    isDelegate: boolean;
    isError: boolean;
    isReady: boolean;
}

export default Vue.extend({
    name: 'CompanyPayment',
    components: {
        MaterialInput,
        MaterialSelect,
        PaymentMethodListItem,
        SkVueCard,
        ValidationProvider,
        ValidationObserver
    },
    directives: {
        mask
    },
    props: {
        org: { type: Object as () => Company, required: true }
    },
    data(): Data {
        return {
            state: AddState.None,
            methods: [],
            newCC: null,
            newACH: null,
            accountTypes: [
                {id: 2, name: 'Personal Checking' },
                {id: 4, name: 'Personal Savings' },
                {id: 8, name: 'Business Checking'}
            ],
            isDelegate: false,
            isError: false,
            isReady: false
        }
    },
    methods: {
        deleteItem(itm: PaymentMethod) {
            let idx = this.methods.indexOf(itm)
            this.methods.splice(idx, 1);
            this.saveMethod(itm);
            this.$emit('onUpdate');
        },
        addACH() {
            const mthd: PaymentMethod = { id: this.methods.length, 
                                          nickname: this.newACH!.name, 
                                          method: this.newACH!, 
                                          type: this.newACH!.type, 
                                          nameOnAccount: this.newACH!.nameOnAccount, 
                                          isPrimary: false, 
                                          isVerified: false };
            this.methods.push(mthd);
            this.saveMethod(mthd);
            this.state = AddState.None;
            this.$emit('onUpdate');
        },
        addCC() {
            const longdt = this.newCC!.expirationDate.split('-');
            this.newCC!.expirationDate = `20${longdt[1]}-${longdt[0]}`;
            const mthd: PaymentMethod = { id: this.methods.length, 
                                          nickname: this.newCC!.name,
                                          method: this.newCC!,
                                          type: PaymentType.CreditCard,
                                          nameOnAccount: this.newCC!.accountHolder,
                                          isPrimary: false,
                                          isVerified: false };
            this.methods.push(mthd);
            this.saveMethod(mthd);
            this.state = AddState.None;
            this.$emit('onUpdate');
        },
        configureCC() {
            this.isError = false;
            this.newCC = { name: '', accountHolder: '', cardNumber: '', expirationDate: '', cardCode: '', postalCode: '' };
            this.state = AddState.AddingCC;
        },
        configureACH() {
            this.isError = false;
            this.newACH = { name: '', bankName: '', routingNumber: '', accountNumber: '', nameOnAccount: '', type: PaymentType.None };
            this.state = AddState.AddingACH;
        },
        async toggleDelegate() {

            try {
                const rslt = await this.$http.put(`${api}/payments/subscriptions/${this.org.id}/delegate/${this.org.parentId}`, { headers: { Accept: "application/json" }});
                if(rslt.ok) {
                    this.isDelegate = !this.isDelegate;
                    this.org.isDelegated = this.isDelegate;
                    this.$emit('onUpdate');
                }

            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
                this.isError = true;
            }
        },
        cancelAdd() {
            this.state = AddState.None;
        },
        onAccountTypeSelect(itm: string) {

            this.isReady = true;
            switch (itm) {
                case 'Personal Checking':
                    this.newACH!.type = PaymentType.CheckingACH;
                    break;
                case 'Personal Savings':
                    this.newACH!.type = PaymentType.SavingsACH;
                    break;
                case 'Business Checking':
                    this.newACH!.type = PaymentType.BusinessCheckingACH;
                    break;
                default:
                    this.isReady = false;
                    this.newACH!.type = PaymentType.None;
                    break;
            }
        },
        async setPrimary(idx: number) {
            const prior = this.methods.find(m => m.isPrimary); // find previous primary method
            if(prior != null){
                prior.isPrimary = false;
            }
            const primary = this.methods.find(m => m.id == idx)!;
            primary.isPrimary = true;
            await this.saveMethod(primary);
        },
        async saveMethod(method: PaymentMethod) {
            
            try {
                const rslt = await this.$http.post(`${api}/payments/subscriptions/${this.org!.id}/register`, this.methods, { headers: { Accept: "application/json" }});
                if(rslt.ok) {
                    const updates = rslt.data as PayModel[];
                    updates.forEach(m => {
                        let mthd = this.methods.find(i => i.id == m.id);
                        if(mthd != undefined) {
                            mthd.id = Number.parseInt(m.name);
                            mthd.isVerified = true;
                            mthd.isPrimary = m.primary ? m.primary : false;
                        }
                    });
                }
            } catch (error) {
                method.isVerified = null;
            }
        }
    },
    computed: {
        isAddingCC(): boolean {
            return (this.state & AddState.AddingCC) != 0
        },
        isAddingACH(): boolean {
            return  (this.state & AddState.AddingACH) != 0;
        },
        hasMethods(): boolean {
            return this.methods.length > 0;
        },
        showAddForm(): boolean {
            return this.state === AddState.None && !this.hasMethods;
        },
        privileges(): EffectivePrivileges {
            return this.$store.getters.privileges;
        },
        isLocked(): boolean {
            return (this.privileges.p & ProfilePermissions.ManagePayments) == 0;
        },
        isParentAdmin(): boolean {
            return (this.privileges.p & ProfilePermissions.ManagePayments) != ProfilePermissions.None && this.$store.getters.homeProfile != this.org.id;
        },
        isDelegationPermitted(): boolean {
            return this.isParentAdmin && !this.isError;
        }

    },
    mounted() {
        this.methods = this.org.payMethods;
        this.isDelegate = this.org.isDelegated === true;
    }
})
