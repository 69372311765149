





































































import Vue from 'vue';
import Company from '@/models/Company';
import Location from '@/models/Location';
// components
import AddLocation from '@/components/profile/AddLocation.vue';
import LocationListItem from '@/components/profile/LocationListItem.vue';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet/dist/leaflet.css';

interface Data {
    isAdding: boolean;
    newLoc: Location | null
}

export default Vue.extend({
    name: 'CompanyLocations',
    components: {
        AddLocation,
        LocationListItem
    },
    props: {
        org: { type: Object as () => Company, required: true }
    },
    data(): Data {
        return {
            isAdding: false,
            newLoc: null
        }
    },
    computed: {
        hasLocations(): boolean {
            return this.org.locations.length > 0;
        }
    },
    methods: {
        async lookupCoordinates(loc: Location) {
            // do geo lookup
            if(loc.streetAddress != null && loc.stateOrProvince != null) {
                const query = `${loc.streetAddress}, ${loc.stateOrProvince}`;
                const provider = new OpenStreetMapProvider();
                
                await provider.search({ query: query }).then((result) => {
                    if(result.length > 0) {
                        loc.lat = Number(result[0].raw.lat);
                        loc.long = Number(result[0].raw.lon);
                    }
                });
            }

            if(loc.lat == null || loc.long == null) {
                loc.long = 0;
                loc.lat = 0;
            }
        },
        async addLocation(loc: Location) {
            loc.rank = this.org.locations.length;
            await this.lookupCoordinates(loc);
            this.org.locations.push(loc);

            this.isAdding = false;
            this.$emit('onUpdateLoc');
        },
        async updateLocation(loc: Location) {
            await this.lookupCoordinates(loc)
            this.isAdding = false;
        },
        removeLocation(idx: number) {
            let rnk = 0;
            this.org.locations.splice(idx, 1);
            this.org.locations.forEach(l => l.rank = rnk++);
            this.$emit('onUpdateLoc');
        },
        setHQ(idx: number) {
            const prior = this.org.locations.find(l => l.hq && l.rank != idx);  //find previous hq
            if(prior != null){
                prior.hq = false;
            }
            this.org.locations[idx].hq = true;
        },
        cancelAdd() {
            this.isAdding = false;
            this.newLoc = null;
        },
        showAddLocation() {
            this.newLoc = {
                companyId: '00000000-0000-0000-0000-000000000000',
                rank: 0,
                name: null,
                streetAddress: null,
                subAddress: null,
                city: null,
                county: null,
                stateOrProvince: null,
                postalCode: null,
                region: null,
                country: 'USA',
                phone: null,
                hq: false,
                lat: null,
                long: null
            };
            this.isAdding = true;
        },
        showEditLocation(loc: Location) {
            this.newLoc = loc;
            this.isAdding = true;
        }
    }
})
