






































  import Vue from 'vue';
  import SubscriptionItem from '@/models/SubscriptionItem';

export default Vue.extend({
  name: 'SubscriptionFeature',
  props: {
    item: { type: Object as () => SubscriptionItem, required: true }
  },
  methods: {
    onSwitchChange() {
      this.item.isSelected = !this.item.isSelected;
      this.$emit('switchValueChanged', this.item.isSelected);
    }
  }
})
