const constants = {
   cardValidations: {
        visa: "^4[0-9]{12}(?:[0-9]{3})?$",
        mastercard: "^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))",
        discover: "^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})",
        amex: "^3[47][0-9]{13}$"
    },
    cardTypes: {
        visa: "visa-card",
        mastercard: "master-card",
        discover: "discover-card",
        amex: "amex"
    },
    cardImages: {
        background: "http://d2cd152e311.surge.sh/assets/images/card-front-background.png",
        visa:  "http://d2cd152e311.surge.sh/assets/images/products/visa-front.png",     
        mastercard: "http://kenkeiter.com/skeuocard/images/products/mastercard-front.png",
        discover: "http://kenkeiter.com/skeuocard/images/products/discover-front.png",
        amex: "https://kenkeiter.com/skeuocard/images/products/amex-front.png"
    }

};
Object.freeze(constants);

export default constants;
