


































































import Vue from 'vue'
import api from '@/api';
import TeamMember from '@/models/TeamMember'
import { CandidatePermissions, JobPermissions, ProfilePermissions } from '@/models/Permissions';
import { EffectivePrivileges } from '@/models/Privileges';

export default Vue.extend({
name: 'TeamMemberItem',
props: {
    companyId: { type: String, required: true },
    teammate: { type: Object as () => TeamMember, required: true }
},
computed: {
    isNone(): boolean {
        return this.teammate.privileges.p == ProfilePermissions.None &&
               this.teammate.privileges.j == JobPermissions.None &&
               this.teammate.privileges.c == CandidatePermissions.None;
    },
    isHiringMgrI(): boolean {
        return (this.teammate.privileges.c & CandidatePermissions.View) == CandidatePermissions.View &&
               (this.teammate.privileges.c & CandidatePermissions.ViewNotes) == CandidatePermissions.ViewNotes &&
               (this.teammate.privileges.c & CandidatePermissions.ViewResume) == CandidatePermissions.ViewResume;
    },
    isHiringMgrII(): boolean {
        return (this.teammate.privileges.c & CandidatePermissions.Share) == CandidatePermissions.Share &&
               (this.teammate.privileges.c & CandidatePermissions.Notate) == CandidatePermissions.Notate;
    },
    isHiringMgrIII(): boolean {
        return (this.teammate.privileges.c & CandidatePermissions.Rate) == CandidatePermissions.Rate &&
               (this.teammate.privileges.c & CandidatePermissions.Workflow) == CandidatePermissions.Workflow;
    },
    isRecruiterI(): boolean {
        return (this.teammate.privileges.j & JobPermissions.Create) == JobPermissions.Create &&
               (this.teammate.privileges.j & JobPermissions.Edit) == JobPermissions.Edit &&
               (this.teammate.privileges.j & JobPermissions.ConfigureQuestions) == JobPermissions.ConfigureQuestions;
    },
    isRecruiterII(): boolean {
        return (this.teammate.privileges.j & JobPermissions.PublishToCareers) == JobPermissions.PublishToCareers &&
               (this.teammate.privileges.j & JobPermissions.Advertise) == JobPermissions.Advertise &&
               (this.teammate.privileges.j & JobPermissions.Deactivate) == JobPermissions.Deactivate;
    },
    isAdmin(): boolean {
        return (this.teammate.privileges.p & ProfilePermissions.Edit) == ProfilePermissions.Edit &&
               (this.teammate.privileges.p & ProfilePermissions.ToggleLimit) == ProfilePermissions.ToggleLimit &&
               (this.teammate.privileges.p & ProfilePermissions.InviteTeammate) == ProfilePermissions.InviteTeammate &&
               (this.teammate.privileges.p & ProfilePermissions.InviteClient) == ProfilePermissions.InviteClient &&
               (this.teammate.privileges.p & ProfilePermissions.ManageUsers) == ProfilePermissions.ManageUsers &&
               (this.teammate.privileges.p & ProfilePermissions.Suspend) == ProfilePermissions.Suspend;
    },
    isPaymentAdmin(): boolean {
        return (this.teammate.privileges.p & ProfilePermissions.ManagePayments) == ProfilePermissions.ManagePayments;
    },
    roles(): string {
        let rset: string[] = [];
        if(this.isNone) {
            return 'None';
        }
        if(this.isHiringMgrI && this.isHiringMgrII && this.isHiringMgrIII) {
            rset.push('Hiring Manager');
        } else if(this.isHiringMgrI || this.isHiringMgrII || this.isHiringMgrIII) {
            rset.push('Hiring Manager*');
        }
        if(this.isRecruiterI && this.isRecruiterII) {
            rset.push('Recruiter');
        } else if(this.isRecruiterI || this.isRecruiterII) {
            rset.push('Recruiter*');
        }
        if(this.isAdmin) {
            rset.push('Admin');
        }
        if(this.isPaymentAdmin) {
            rset.push('Payment Admin');
        }
        return rset.join(', ');
    }
},
methods: {
    async setPermissions(idx: Number) {

        try {

            const rslt = await this.$http.put(`${api}/profiles/${this.companyId}/teammember/${idx}`, JSON.stringify(this.teammate.username), { headers: {"Content-Type": "application/json"}});
            this.teammate.privileges = rslt.data as EffectivePrivileges;
            this.teammate.info = this.roles;

        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
            return null;
        }
    }
}
})
