








































import Vue from 'vue';
import api from '@/api';
import Company from '@/models/Company';
import Progress from '@/models/Progress';
// components
import ProfileRequirements from '@/components/profile/ProfileRequirements.vue';
import CompanyDetails from '@/components/profile/CompanyDetails.vue';
import CompanyLocations from '@/components/profile/CompanyLocations.vue';
import CompanyPayment from '@/components/profile/CompanyPayment.vue';
import SubscriptionFeature from '@/components/profile/SubscriptionFeature.vue';
import TeamMembers from '@/components/profile/TeamMembers.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';

interface Data {
  org: Company | null;
  progress: Progress;
  isReady: boolean;
}

export default Vue.extend({
  name: 'EditProfile',
  components: {
    ProfileRequirements,
    CompanyDetails,
    CompanyLocations,
    CompanyPayment,
    SubscriptionFeature,
    TeamMembers,
    BreadCrumb
  },
  data(): Data {
    return {
      org: null,
      progress: {
        details: true,
        locations: false,
        payments: null,
        teamMembers: true,
        plan: null,
      }, // null -> hides indicator
      isReady: false,
    };
  },
  methods: {
    async saveEdits() {
      const pid = this.org!.id;
      try {
        // post to 'edit' endpoint
        await this.$http.put(`${api}/profiles/${pid}/edit`, this.org, {
          headers: { Accept: 'application/json' },
        });
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      } finally {
        // navigate to new org's board
        this.$router.push(`/boards/${pid}`);
      }
    },
    updateLocations() {
      this.progress.locations = this.org!.locations.length > 0;
    },
    async updatePayments() {
      this.progress.payments = this.hasPayments;
    },
  },
  computed: {
    hasPayments(): boolean {
      return this.org!.payMethods.length > 0 || this.org!.isDelegated === true;
    },
    showPayments(): boolean {
      return !this.org!.isInvoiced;
    },
  },
  updated() {
    if (this.$route.hash) {
      const jmp = document.querySelector(this.$route.hash);
      jmp!.scrollIntoView();
    }
  },
  async mounted() {
    let orgId = this.$route.params.pid;
    if (orgId != undefined) {
      // edit an existing org
      try {
        this.$store.commit('setIsLoading', true);
        const rslt = await this.$http.get(`${api}/profiles/${orgId}/edit`);
        this.org = rslt.data as Company;

        this.updateLocations();
        this.progress.payments = this.showPayments ? this.hasPayments : null;
        this.isReady = true;
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
      finally {
        this.$store.commit('setIsLoading', false);
      }
    }
    // jump to payments section if anchor was specified
    if (this.$route.hash) {
      const jmp = document.querySelector(this.$route.hash);
      jmp!.scroll();
    }
  },
});
