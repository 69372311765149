
































import Vue from 'vue'
import PaymentMethod, { BankAccount, CreditCard, PaymentType } from '@/models/PaymentMethod';

export default Vue.extend({
    name: 'PaymentMethodListItem',
    props: {
        item: { type: Object as () => PaymentMethod, required: true }
    },
    methods: {
        deleteItem() {
            this.$emit('onDelete');
        },
        setPrimary() {
            this.item.isPrimary = true;
            this.$emit('onPrimary', this.item.id);
        }
    },
    computed: {
        isCC(): boolean {
            return this.item.type === PaymentType.CreditCard;
        },
        lastFour(): string {
            const mthd = this.item.method;
            return this.isCC ? (mthd as CreditCard).cardNumber.slice(-4) : (mthd as BankAccount).accountNumber.slice(-4);
        },
        daysToExpire(): number | null {
            const cc = this.isCC ? this.item.method as CreditCard : null;
            if(cc != null) {
                const orig = cc.expirationDate.split('-', 2);
                const dt = new Date();
                const exp = new Date(Number(orig[0]), Number(orig[1]));
                const boundary = new Date(Number(orig[0]), Number(orig[1]) - 1); //new Date(cc.expirationDate + '-01T00:00:00Z');
                // alert(`orig: ${orig}\ntoday: ${dt}\nboundary: ${boundary}\nexp: ${exp}\ncalc: ${30 - dt.getDate()}`)

                return dt >= exp ? 0 : dt >= boundary ? 30 - dt.getDate() : null;

            } else {
                return null;
            }
        }
    }
})
