<template>
    <div>
        <div class="card" @mouseout="isNameValid()">
            <div
                class="card__part card__front"
                :style="
                    'background-image: url(' +
                    cardImages.card +
                    '),url(' +
                    cardImages.background +
                    ');'
                "
                :class="{
                    'card-front-bg': isFrontPage,
                    card__front_rotate: !isFrontPage,
                    'form-active': !isFrontPageFormValid,
                }"
            >
            <div v-if="isFrontPageFormValid" class="flip-tab front" 
                :class="{ warn: !isBackPageFormValid && !csvFieldValid, prompt: isBackPageFormValid }"
                @click="isFrontPage = !isFrontPage">
                <p v-if="!csvFieldValid">
                <strong>Click here</strong> to <br />fill in the other side.
                </p>
                <p v-if="isBackPageFormValid">
                Forget something?<br />
                Flip the card over.
                </p>
            </div>
            <div class="card_numer">
                <input
                class="card_input"
                type="number"
                v-for="(key, i) in cardNumberFields"
                :key="i"
                :data-length="key.length"
                :data-index="i"
                :ref="`input-number-${i}`"
                v-model="key.value"
                @blur="isCardNumberValid()"
                :class="{ 'invalid-input': cardNumberFieldValid === false }"
                placeholder="****"
                @input="handleCardNumberInput($event)"
                />
            </div>
            <div class="card__expires">
                <div class="card_input_group">
                <input
                    type="number"
                    class="card_input"
                    :class="{ 'invalid-input': expirationFieldMMValid === false }"
                    v-model="expirationFieldMM"
                    placeholder="MM"
                    @blur="isMMValid()"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="2"
                />
                /
                <input
                    type="number"
                    class="card_input"
                    :class="{ 'invalid-input': expirationFieldYYValid === false }"
                    @blur="isYYValid()"
                    v-model="expirationFieldYY"
                    placeholder="YY"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="2"
                />
                </div>
            </div>
            <div class="card__holder">
                <input
                type="text"
                class="card_input"
                v-model="cc.accountHolder"
                maxlength="22"
                :class="{ 'invalid-input': nameFieldValid === false }"
                @blur="isNameValid()"
                placeholder="YOUR NAME"
                />
            </div>
            </div>

            <div
            class="card__part card__back"
            :class="{
                card__back_rotate: !isFrontPage,
                'form-active': !isBackPageFormValid,
            }">
            <div class="flip-tab back" :class="{ warn: !isFrontPageFormValid, prompt: isFrontPageFormValid }" @click="isFrontPage = !isFrontPage">
                <p v-if="!isFrontPageFormValid">
                <strong>Click here</strong> to <br />fill in the other side.
                </p>
                <p v-if="isFrontPageFormValid">
                Forget something?<br />
                Flip the card over.
                </p>
            </div>

            <div class="card__black-line"></div>
            <div class="card__back-content">
                <div class="card__secret">
                <div class="card__secret--last">
                    <input type="number" class="card_input" :class="{ 'invalid-input': csvFieldValid === false }"
                           oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" :maxlength="isAmexCard(cardNumberField) ? '4' : '3'"
                          :placeholder="isAmexCard(cardNumberField) ? '****' : '***'" v-model="cc.cardCode"  @blur="isCsvValid()"/>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import constants from "@/assets/environment"
    import { CreditCard } from '@/models/PaymentMethod'
    import Vue from 'vue'

    // export default Vue.extend({
    export default {
        name: "SkVueCard",
        props: {
            cc: { type: Object, required: true } // cc: { type: Object as () => CreditCard, required: true }
        },
        data() {
            return {
                isFrontPage: true,
                isFormActive: true,
                cardNumberFields: [
                    { length: 4, value: "" },
                    { length: 4, value: "" },
                    { length: 4, value: "" },
                    { length: 4, value: "" },
                ],
                cardNumberFieldValid: null,
                expirationFieldMM: "",
                expirationFieldMMValid: null,
                expirationFieldYY: "",
                expirationFieldYYValid: null,
                nameField: "",
                nameFieldValid: null,
                csvField: "",
                csvFieldValid: null,
                cardType: "",
                cardImages: {
                    card: "",
                    background: constants.cardImages.background,
                }
            };
    },
    methods: {
        isVisaCard(val) {
            const regex = new RegExp(constants.cardValidations.visa);
            return regex.test(val);
        },
        isMasterCard(val) {
            const regex = new RegExp(constants.cardValidations.mastercard);
            return regex.test(val);
        },
        isDiscoverCard(val) {
        const regex = new RegExp(constants.cardValidations.discover);
        return regex.test(val);
        },
        isAmexCard(val) {
            const regex = new RegExp(constants.cardValidations.amex);
            console.log("isAmex: ", regex.test(val), val);
            return regex.test(val);
        },
        handleCardNumberInput(e) {
            let value = e.target.value;
            let index = parseInt(e.target.dataset.index);
            let maxlength = e.target.dataset.length;

            if (this.cardNumberFields[index].value.length > maxlength) {
                e.preventDefault();
                this.cardNumberFields[index].value = value.slice(0, 4);

                if (this.$refs[`input-number-${parseInt(index + 1)}`]) {
                try {
                    this.$refs[`input-number-${parseInt(index + 1)}`][0].focus();
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                }
                }
                return;
            }

            if (value.length >= maxlength) {
                if (typeof this.cardNumberFields[index + 1] == "undefined") {
                    e.preventDefault();
                    return;
                }
                if (this.$refs[`input-number-${parseInt(index + 1)}`][0]) {
                    this.$refs[`input-number-${parseInt(index + 1)}`][0].focus();
                }
                e.preventDefault();
            }
        },
        frontPageNotFilled() {
            return (
                this.cardNumberFieldValid === null &&
                this.expirationFieldMMValid === null &&
                this.expirationFieldYYValid === null &&
                this.nameFieldValid === null
            );
        },
        isNameValid() {
            if (this.cc.accountHolder.length > 2) {
                this.nameFieldValid = true;
                return true;
            }
            this.nameFieldValid = false;
            return false;
        },
        isMMValid() {
            if (this.expirationFieldMM > 0 && this.expirationFieldMM < 13) {
                this.expirationFieldMMValid = true;
                return true;
            }
            this.expirationFieldMMValid = false;
            return false;
        },
        isYYValid() {
            if (this.expirationFieldYY > 20 && this.expirationFieldYY < 100) {
                this.expirationFieldYYValid = true;
                return true;
            }
            this.expirationFieldYYValid = false;
            return false;
        },
        isExpirationValid() {
            if(this.expirationFieldMMValid && this.expirationFieldYYValid) {
                this.cc.expirationDate = `${this.expirationFieldMM}-${this.expirationFieldYY}`;
                return true; 
            }
            return false;
        },
        isCardNumberValid() {
            if (this.cardNumberField.length > 5) {
                if (this.isDiscoverCard(this.cardNumberField)) {
                    this.cardType = constants.cardTypes.discover;
                    this.cardImages.card = constants.cardImages.discover;
                } else if (this.isMasterCard(this.cardNumberField)) {
                    this.cardType = constants.cardTypes.mastercard;
                    this.cardImages.card = constants.cardImages.mastercard;
                } else if (this.isVisaCard(this.cardNumberField)) {
                    this.cardType = constants.cardTypes.visa;
                    this.cardImages.card = constants.cardImages.visa;
                } else if (this.isAmexCard(this.cardNumberField)) {
                    console.log("amex");
                    this.cardType = constants.cardTypes.amex;
                    this.cardImages.card = constants.cardImages.amex;
                } else {
                    // eslint-disable-next-line
                    console.log("unknown card!");
                    this.cardType = "";
                    this.cardImages.card = "";
                    this.cardNumberFieldValid = false;
                    return false;
                }
            }
            if (this.cardNumberField.length === 16 || this.cardNumberField.length === 15 && this.isAmexCard(this.cardNumberField)) {
                this.cc.cardNumber = this.cardNumberField;
                this.cardNumberFieldValid = true;
                return true;
            }
            this.cardNumberFieldValid = false;
            return false;
        },
        isCsvValid() {
            if (this.cc.cardCode.length === 3 && !this.isAmexCard(this.cardNumberField) ||this.cc.cardCode.length === 4 && this.isAmexCard(this.cardNumberField)) {
                this.csvFieldValid = true;
                return true;
            }
            this.csvFieldValid = false;
            return false;
        },
    },
    watch: {
        nameField: function () {
        this.isNameValid();
        },
        expirationFieldMM: function () {
        this.isMMValid();
        },
        expirationFieldYY: function () {
        this.isYYValid();
        },
        cardNumberField: function () {
        this.isCardNumberValid();
        },
        'cc.cardCode': function () {
        this.isCsvValid();
        },
        isFrontPageFormValid: function(val) {
            if (val) {
                this.isFrontPage = false;
            }
        }
    },
    computed: {
        cardNumberField() {
        let value = "";
        for (let field of this.cardNumberFields) {
            value += field.value;
        }
        return value;
        },
        isFrontPageFormValid() {
            return (
                this.cardNumberFieldValid &&
                this.isExpirationValid() &&
                this.expirationFieldMMValid &&
                this.expirationFieldYYValid &&
                this.nameFieldValid
            );
        },
        isBackPageFormValid() {
            return this.csvFieldValid;
        }
    }
}
    // });
</script>

<style scoped>
    .flip-tab.warn {
        background: #ff655a
            url("http://d2cd152e311.surge.sh/assets/images/card-invalid-indicator.png")
            no-repeat;
        background-size: 35px 35px;
    }

    .flip-tab.prompt {
        background: #00cfa7
            url("http://d2cd152e311.surge.sh/assets/images/card-valid-anim.gif")
            no-repeat;
        background-size: 35px 35px;
    }

    .flip-tab.back {
        background-position: 5px !important;
    }

    .flip-tab.front {
        background-position: 140px;
    }
    .flip-tab.front p {
        margin-left: 15px;
    }

    .flip-tab.front:hover {
        right: -30px;
    }
    .flip-tab.back:hover {
        left: -30px;
    }
    .flip-tab.front {
        right: -1.25em;
        top: 1em;
    }

    .flip-tab.back {
        left: -1.25em;
        top: 1em;
    }

    .flip-tab.back p {
        margin-right: 20px;
        margin-left: 45px;
        margin-top: 10px;
    }

    .flip-tab p {
        margin: 0.6em;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        color: #fff;
        font-size: 13px;
        line-height: 1.2;
    }

    .flip-tab {
        position: absolute;
        cursor: pointer;
        width: 182px;
        display: block;
        background: #666;
        height: 50px;
        border-radius: 3.55em;
        -webkit-border-radius: 3.55em;
        -ms-border-radius: 3.55em;
        -moz-border-radius: 3.55em;
        -o-border-radius: 3.55em;
        transition: all 0.25s ease;
        -webkit-transition: all 0.25s ease;
        -ms-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
    .card {
        width: 320px;
        height: 190px;
        -webkit-perspective: 600px;
        -moz-perspective: 600px;
        perspective: 600px;
    }

    .card__part {
        box-shadow: 1px 1px #aaa3a3;
        top: 0;
        position: absolute;
        z-index: 1000;
        left: 0;
        display: inline-block;
        width: 320px;
        height: 190px;
        background-color: #eee;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 8px;
        -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
    }

    .card__front {
        padding: 18px;
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
    }

    .card__back {
        padding: 18px 0;
        -webkit-transform: rotateY(-180deg);
        -moz-transform: rotateY(-180deg);
    }

    .card__black-line {
        margin-top: 5px;
        height: 38px;
        background-color: #777;
    }

    .card__logo {
        height: 16px;
    }

    .card__front-logo {
        position: absolute;
        top: 18px;
        right: 18px;
    }
    .card__square {
        border-radius: 5px;
        height: 30px;
    }

    .card_numer {
        display: flex;
        word-spacing: 4px;
        font-size: 20px;
        letter-spacing: 2px;
        color: #fff;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 50px;
    }
    input {
        background-color: transparent;
        box-shadow: unset;
    }
    .card_numer input {
        height: 28px;
        font-size: 22px;
    }

    .form-active input {
        background-color: white;
        box-shadow: inset 1px 1px 1px 1px #ccc;
        -webkit-box-shadow: inset 1px 1px 1px 1px #ccc;
        -ms-box-shadow: inset 1px 1px 1px 1px #ccc;
        -moz-box-shadow: inset 1px 1px 1px 1px #ccc;
        -o-box-shadow: inset 1px 1px 1px 1px #ccc;
    }

    .card_input {
        text-align: center;
        max-width: 68px;
        font-weight: bold;
        border: none;
        outline: none;
        color: #777;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -ms-border-radius: 0px;
        -moz-border-radius: 0px;
        -o-border-radius: 0px;
        transition: background-color 300ms ease;
        -webkit-transition: background-color 300ms ease;
        -ms-transition: background-color 300ms ease;
        -moz-transition: background-color 300ms ease;
        -o-transition: background-color 300ms ease;
        margin: 0 3px;
        box-sizing: border-box;
        font-family: "ocraregular", "OCR A Std", "OCR A", Courier, "Courier New", monospace;
    }

    .invalid-input {
        box-shadow: inset 0px 0px 2px 1px #ff655a !important;
        -webkit-box-shadow: inset 0px 0px 2px 1px #ff655a !important;
        -ms-box-shadow: inset 0px 0px 2px 1px #ff655a !important;
        -moz-box-shadow: inset 0px 0px 2px 1px #ff655a !important;
        -o-box-shadow: inset 0px 0px 2px 1px #ff655a !important;
        border: 1px solid #ff655a !important;
    }

    .card__expires {
        margin-left: 115px;
        margin-top: 40px;
    }

    .card__holder {
        margin-left: 10px;
    }

    .card__expires input {
        width: 28px;
        height: 18px;
    }

    .card_input_group {
        display: flex;
    }

    .card__holder input {
        width: 170px;
        max-width: 205px;
        height: 18px;
        text-align: left;
        padding-left: 15px;
    }

    .card__label {
        font-size: 10px;
        text-transform: uppercase;
        color: #333;
        letter-spacing: 1px;
    }

    .card__info {
        margin-bottom: 0;
        margin-top: 5px;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .card__back-content {
        padding: 15px 15px 0;
    }
    .card__secret--last {
        color: #303030;
        text-align: right;
        margin: 0;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .card__secret--last input {
        max-width: 38px;
        padding-left: 5px;
    }

    .card__secret {
        padding: 12px 5px;
        background-color: #fff;
        position: relative;
    }

    .card__secret:before {
        content: "";
        position: absolute;
        top: -3px;
        left: -3px;
        height: calc(100% + 6px);
        width: calc(100% - 42px);
        border-radius: 4px;
        background: repeating-linear-gradient(
            45deg,
            #ededed,
            #ededed 5px,
            #f9f9f9 5px,
            #f9f9f9 10px
        );
    }

    .card__front_rotate {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
    }

    .card__back_rotate {
        -webkit-transform: rotateY(0deg);
        -moz-transform: rotateY(0deg);
    }
</style>