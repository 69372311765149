











































































































































import Vue from 'vue';
import api from '@/api';
import Company from '@/models/Company';
import Industry from '@/models/Industry';
// components
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialMarkdownEditor from '@/components/MaterialMarkdownEditor.vue';
import MaterialSelect from '@/components/MaterialSelect.vue';
import ImageUploader from '@/components/ImageUploader.vue';
// validation
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, regex, email } from 'vee-validate/dist/rules';


extend('required', {
    ...required,
    message: '{_field_} is required'
})

extend('regex', {
    ...regex,
    message: "Please specify either 'http://' or 'https://' for your website"
})

extend('email', {
    ...email,
    message: '{_field_} must be a valid email'
});

interface Data {
    industries: Industry[],
    draggingProfileImageUpload: boolean,
    draggingCompanyImageUpload: boolean
}

export default Vue.extend({
    name: 'CompanyDetails',
    props: {
        org: { type: Object as () => Company, required: true },
        isCreate: { type: Boolean, required: false, default: false }
    },
    components: {
        MaterialInput,
        MaterialMarkdownEditor,
        MaterialSelect,
        ImageUploader,
        ValidationObserver,
        ValidationProvider
    },
    data(): Data {
        return {
            industries: [],
            draggingProfileImageUpload: false,
            draggingCompanyImageUpload: false
        }
    },
    methods: {
        createOrg() {
            this.$emit('onSave', this.org);
        },
        updateLogo(uri: string) {
            this.org.logo = uri;
        },
        updateBanner(uri: string) {
            this.org.banner = uri;
        },
        sectorChanged: function(event: any) {
            this.org.subIndustry = '';
        }
    },
    computed: {
        industryId(): number | null {
            const industry = this.industries.find(industry => industry.name == this.org.industry);
            return industry ? industry.id : null;
        },
        getIndustries(): Industry[] {
            return this.industries.filter(industry => industry.parentId == null);
        },
        getSubIndustries(): Industry[] {
            const parentId = this.industryId;
            return parentId ? this.industries.filter(industry => industry.parentId == parentId) : [];
        }
    },
    async mounted() {
        try{
            const rslt = await this.$http.get(`${api}/industries`);
            this.industries = rslt.data;
        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
        }
    }
})
