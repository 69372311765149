






































import Vue from 'vue';
import Progress from '@/models/Progress';
import CompletionIndicator from '@/components/CompletionIndicator.vue';

interface Data {
    progress: Progress;
}

export default Vue.extend({
    name: 'ProfileRequirements',
    components: {
        CompletionIndicator
    },
    props: {
        value: { type: Object as () => Progress, required: true }
    },
    data(): Data {
        return {
            progress: this.value
        }
    }
})
