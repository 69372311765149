








import Vue from 'vue'
import CompanyAbstract from '@/models/CompanyAbstract';
// components
import ClientInviteModal from '@/components/modals/ClientInviteModal.vue';

interface Data {
    org: CompanyAbstract | null;
}

export default Vue.extend({
    name: 'AddAdminBackdrop',
    components: {
        ClientInviteModal
    },
    data(): Data {
        return {
            org: null
        }
    },
    methods: {
        hide() {
            this.$bvModal.hide('invite-modal');

            if(this.org!.id != this.$store.getters.homeProfile) {
                this.$router.push({ name: 'NavBoard', params: { pid: this.$store.getters.homeProfile }});
            } else {
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('userSession');
                this.$router.replace({ name: 'Login' });
            }
            this.$emit('onHide');
        }
    },
    mounted() {
        const str = sessionStorage.getItem('org')!;
        this.org = JSON.parse(str) as CompanyAbstract;
        this.$nextTick(function() {
            this.$bvModal.show('invite-modal');
        })
        sessionStorage.removeItem('org');
    }
})
