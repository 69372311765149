























import Vue from 'vue';
import Location from '@/models/Location';

export default Vue.extend({
    name: 'LocationListItem',
    props: {
        location: { type: Object as () => Location, required: true }
    },
    methods: {
        editLocation() {
            this.$emit('onEdit', this.location);
        },
        setPrimary() {
            this.$emit('onPrimary', this.location.rank);
        },
        remove() {
            this.$emit('onDelete', this.location.rank);
        }
    }
})
