





































































    import Vue from 'vue';
    import api from '@/api';
    import TeamMember from '@/models/TeamMember';
    import Company from '@/models/Company';
    import { ProfilePermissions } from '@/models/Permissions';
    import { EffectivePrivileges } from '@/models/Privileges';
    // components
    import ClientInviteModal from '@/components/modals/ClientInviteModal.vue';
    import TeamMemberItem from '@/components/profile/TeamMemberItem.vue';

    interface Data {
        associates: TeamMember[];
        teamMembers: TeamMember[];
        showInvites: boolean;
        inviteFields: object[];
        invitees: object[];
    }

  export default Vue.extend({
    name: 'TeamMembers',
    components: {
        ClientInviteModal,
        TeamMemberItem
    },
    props: {
        org: { type: Object as () => Company, required: true }
    },
    data(): Data {
        return {
            teamMembers: [],
            associates: [],
            showInvites: false,
            inviteFields: [{ key: "name" }, { key: "status" } ],
            invitees: []
        };
    },
    computed: {
        privileges(): EffectivePrivileges {
            return this.$store.getters.privileges;
        },
        isLocked(): boolean {
            return (this.privileges.p & ProfilePermissions.ManageUsers) == 0;
        },
        hasTeammates(): boolean {
            return this.associates.length > 0;
        }
    },
    methods: {
        showInvite() {
            this.$bvModal.show('invite-modal');
        },
        async setPermissions(val: number) {

        },
        saveProfile() {
            this.$emit('onSave');
        }
    },
    async mounted() {
        try{
            const rslt = await this.$http.get(`${api}/profiles/${this.org.id}/associates`);
            this.associates = rslt.data as TeamMember[];

            this.associates.forEach(a => {
                if (a.isInvite) {
                    this.invitees.push({
                        name: `<span class='c-gray'>${a.firstName} ${a.lastName} <br /> <small> ${a.username}</small></span>`,
                        status: `<span class='c-gray'>${a.info}</span>`
                    });
                } else {
                    this.teamMembers.push(a);
                }
            });

        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
        }
    }
})
