import { UUID, NullableString } from '@/models/Types';
import PaymentMethod from '@/models/PaymentMethod';
import Location from '@/models/Location';
import SubscriptionItem from './SubscriptionItem';

export default interface Company {
    id: UUID | null,
    parentId: UUID | null,
    companyName: string,
    industry: NullableString,
    subIndustry: NullableString,
    employerSize: string,
    aboutUs: string,
    whyWorkHere: string,
    locations: Location[],
    payMethods: PaymentMethod[],
    subscriptions: SubscriptionItem[],
    logo: NullableString,
    banner: NullableString,
    website: NullableString,
    isLimited: boolean,
    isInvoiced: boolean,
    isChannel: boolean,
    isDelegated: boolean | null,
    billingEmail: string,
    notificationEmail: string
}

export const DefaultCompany: Company = {
    id: '00000000-0000-0000-0000-000000000000',
    parentId: null,
    companyName: '',
    industry: null,
    subIndustry: null,
    employerSize: '',
    aboutUs: '',
    whyWorkHere: '',
    locations: [],
    payMethods: [],
    subscriptions: [],
    logo: null,
    banner: null,
    website: null,
    isLimited: true,
    isInvoiced: false,
    isChannel: false,
    isDelegated: null,
    billingEmail: '',
    notificationEmail: ''
}