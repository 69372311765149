export default interface PaymentMethod {
    id: number;
    nickname: string;
    method: CreditCard | BankAccount;
    type: PaymentType;
    nameOnAccount: string;
    isPrimary: boolean;
    isVerified: boolean | null;
}

export interface CreditCard {
    name: string;
    accountHolder: string;
    cardNumber: string;
    expirationDate: string;
    cardCode: string;
    postalCode: string;
}

export interface BankAccount {
    name: string;
    bankName: string;
    routingNumber: string;
    accountNumber: string;
    nameOnAccount: string;
    type: PaymentType;
}

export enum PaymentType {
    None = 0,
    CreditCard = 1,
    CheckingACH = 2,
    SavingsACH = 4,
    BusinessCheckingACH = 8
}
