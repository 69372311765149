







































































import _ from "lodash";
import Vue from 'vue';
import api from '@/api';
import { UUID } from "@/models/Types";
import Company, { DefaultCompany } from '@/models/Company';
import UserSession from '@/models/UserSession';
import { ProfilePermissions } from '@/models/Permissions'
import { EffectivePrivileges } from '@/models/Privileges';
import Progress from '@/models/Progress';
// components
import ProfileRequirements from '@/components/profile/ProfileRequirements.vue';
import CompanyDetails from '@/components/profile/CompanyDetails.vue';
import CompanyLocations from '@/components/profile/CompanyLocations.vue';
import CompanyPayment from '@/components/profile/CompanyPayment.vue';

interface SessionVM extends UserSession {
    payload: Company;
}

interface Data {
    org: Company;
    currentForm: number;
    progress: Progress;
}

export default Vue.extend({
    name: 'CreateChildProfile',
    components: {
        ProfileRequirements,
        CompanyDetails,
        CompanyLocations,
        CompanyPayment
    },
    data(): Data {
        return {
            org: _.cloneDeep(DefaultCompany),
            currentForm: 0,
            progress: { details: false, locations: false, payments: null, teamMembers: null, plan: null }, // null -> hides indicator
        }
    },
    methods: {
        async createOrg(company: Company) {

            try {
                // post to 'create' endpoint protected by hlink
                const rslt = await this.$http.post(`${api}/profiles/${this.org.parentId}/create-child`, company, { headers: { Accept: "application/json" }});
                const rspn: SessionVM = rslt.data;

                this.org.id = rspn.payload.id;

                // extract and create new user session
                var session: UserSession = { 
                                token: rspn.token, 
                                primaryOrg: rspn.primaryOrg, 
                                currentOrg: rspn.currentOrg, 
                                currentPrivileges: rspn.currentPrivileges };

                // save session vars
                sessionStorage.setItem("token", session.token);
                this.$store.commit('setUserSession', session);

                this.progress.details = true;
                this.currentForm++;

            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
            }
        },
        async updateLocations() {
            this.progress.locations = this.hasLocations;
        },
        updatePayments() {
            // alert(`outer: ${this.org.isDelegated} - payments: ${this.hasPayments}`)
            this.progress.payments = this.hasPayments;
        },
        async complete() {
            try {
                // post to 'edit' endpoint
                await this.$http.put(`${api}/profiles/${this.org.id}/edit`, this.org, { headers: { 'Accept':'application/json' } });

            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
            } finally {
                this.$router.push({ name: 'NavBoard', params: { pid: this.org.id!}});
            }
        }
    },
    computed: {
        hasLocations(): boolean {
            return this.org.locations.length > 0;
        },
        hasPayments(): boolean {
            return this.org.payMethods.length > 0 || this.org.isDelegated === true;
        },
        enablePayments(): boolean { // show payments progress indicator
            return (this.privileges.p & ProfilePermissions.ManagePayments) != ProfilePermissions.None;
        },
        showPayments(): boolean { // unhide payments section
            return this.hasLocations && this.progress.payments != null;
        },
        showReady(): boolean {
            return this.showPayments ? this.hasPayments : this.hasLocations;
        },
        privileges(): EffectivePrivileges {
            return this.$store.getters.privileges;
        },
        isAdmin(): boolean {
            return this.$store.getters.isWerkberry;
        }
    },
    async mounted() {
        // validate the link
        const parentId = this.$route.params.cid as UUID;
        this.org.parentId = parentId;
        if(this.enablePayments) { // unhide payments progress indicator
            this.progress.payments = false;
        }
        this.currentForm = 1;
    }
})
